import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import ReactGA from 'react-ga4';

import "./home-carousel.css";
import ContactUsModal from "components/contact-us-modal/contact-us-modal";

import bookNow from 'assets/booknow.png';

const ImageSlider = ({ images, interval = 3000, showHeader = true, packageLocationFromURL = '' }) => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [index, setIndex] = useState(0);


    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const openCOntactModal = () => {
        ReactGA.event({
            category: 'Enquiry',
            action: 'click',
            label: 'Enquiry Button Click is clicked'
          });
        setIsContactModalOpen(true);
    };

    const closeNavContactModal = () => {
        setIsContactModalOpen(false);
    };


    useEffect(() => {
        const intervalId = setInterval(() => {
            const newIndex = index === images.length - 1 ? 0 : index + 1;
            setIndex(newIndex);
        }, interval);

        return () => clearInterval(intervalId);
    }, [index, images.length, interval]);

    let exploreText = "Explore with Saveller";
    if (packageLocationFromURL === 'kerala') {
        exploreText = "Explore Kerala with Saveller";
    } else if (packageLocationFromURL === 'northeast') {
        exploreText = "Explore North east with saveller";
    }

    let topPercentage = '20%';
    let leftPercentage = '43%'
    if (packageLocationFromURL === 'kerala') {
        topPercentage = '16%';

    } else if (packageLocationFromURL === 'northeast') {
        topPercentage = '17%';
    }

    const headerTextStyle = {
        position: 'absolute',
        top: topPercentage,
        left: leftPercentage,
        fontSize: '44px',
        color: 'white',
        textAlign: 'right',
        textTransform: 'uppercase',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
    };

    return (
        <div id='home-slider-container'>
            <Carousel activeIndex={index} onSelect={handleSelect} className='slide-home'>
                {images.map((image, idx) => (
                    <Carousel.Item key={idx} >
                        <img
                            className="w-100 carousel-image"
                            src={image.src}
                            alt={image.alt}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
            <div className="headerText merriweather-light" style={headerTextStyle}>
                <div className="animated-text">{exploreText}</div>
                <div className="animated-text-Button">
                    <button className="whiteButton bookNow d-flex" onClick={openCOntactModal}>
                        <img src={bookNow} alt="Book Now" />
                        BOOK NOW
                    </button>
                </div>
            </div>
            <ContactUsModal isOpenContactModal={isContactModalOpen} onCloseContact={closeNavContactModal} />

        </div>
    );
};

export default ImageSlider;
