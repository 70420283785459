import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./filter.css";
import ReactGA from 'react-ga4';

function ButtonGroup({ onFilterChange, isKerala }) {
    const [activeButton, setActiveButton] = useState(1);

    console.log(isKerala);
    const handleClick = (buttonNumber, daysRange) => {
        ReactGA.event({
            category: 'Packages',
            action: 'switch',
            label: `package switch `
        });

        setActiveButton(buttonNumber);
        onFilterChange(daysRange);
    };

    return (
        <div className="container-group roboto-regular" id="button-group">
            <div className="row">
                <div className="col-md-6">
                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button type="button" className={`btn btn-primary ${activeButton === 1 ? "active" : ""}`}
                            onClick={() => handleClick(1, { min: 0, max: Infinity })}> All{" "}
                        </button>
                        {isKerala && (
                            <button type="button" className={`btn btn-primary ${activeButton === 2 ? "active" : ""}`}
                                onClick={() => handleClick(2, { min: 1, max: 3 })}> 1-3D
                            </button>
                        )}
                        {isKerala && (
                            <button type="button" className={`btn btn-primary ${activeButton === 3 ? "active" : ""}`}
                                onClick={() => handleClick(3, { min: 4, max: 6 })}> 4-6D
                            </button>
                        )}
                        <button type="button" className={`btn btn-primary ${activeButton === 4 ? "active" : ""}`}
                            onClick={() => handleClick(4, { min: 7, max: 10 })}> 7-10D{" "}
                        </button>
                        <button type="button" className={`btn btn-primary ${activeButton === 5 ? "active" : ""}`}
                            onClick={() => handleClick(5, { min: 10, max: Infinity })}> 10D+{" "}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ButtonGroup;
