import React from "react";

import './terms-condition.css'

const Privacy = (proms) => {

    return (
        <>
            <div className="privacy-heading cinzel-saveller"> Booking and cancellation policy</div>
            <div className="privacy-content merriweather-light ml-3">
                <div className="policy-content-font">Booking Policy
                    <br />
                    <span className="policy-content-font ms-1">1. Payment Terms:</span>
                    <ul className="policy-content-font ms-1">
                        <li>A deposit of 50% of the total tour cost is required at the time of booking.</li>
                        <li>The remaining 50% must be paid at least 5 days before the scheduled tour date.</li>
                        <li>Failure to complete the payment before the cut-off date will result in automatic cancellation of the booking.</li>
                    </ul>

                    <span className="policy-content-font ms-1">2. Early Check-In Assistance:</span>
                    <ul className="policy-content-font ms-1">
                        <li>We offer early check-in assistance where possible.</li>
                        <li>Early check-in is subject to the discretion and availability of the hotel and cannot be guaranteed by Saveller Tours and Travels.</li>
                    </ul>
                </div>
                <div className="policy-content-font">Cancellation Policy
                    <br />

                    <span className="policy-content-font ms-1">1. Cancellation by Client:</span>
                    <ul className="policy-content-font ms-1">
                        <li>Cancellations made more than 30 days before the tour date will result in a full refund of the deposit.</li>
                        <li>Cancellations made between 15 and 30 days before the tour date will incur a 25% cancellation fee on the total tour cost.</li>
                        <li>Cancellations made between 5 and 15 days before the tour date will incur a 50% cancellation fee on the total tour cost.</li>
                        <li>No refunds will be provided for cancellations made less than 5 days before the tour date.</li>
                    </ul>

                    <span className="policy-content-font ms-1">2. Cancellation Due to Natural Calamities:</span>
                    <ul className="policy-content-font ms-1">
                        <li>Saveller Tours and Travels will not be held responsible for cancellations or interruptions due to natural calamities such as floods, roadways being destroyed, or airports not functioning.</li>
                        <li>We will make every effort to reschedule the tour date, but this is subject to vendor availability and cannot be guaranteed.</li>
                        <li>In the event that a trip must be canceled due to such calamities, the refund amount will be determined based on the advance amounts already paid to vendors.</li>
                        <li>Any remaining balance, after deducting vendor costs, will be refunded to the client.</li>
                    </ul>
                </div>
                <div className="policy-content-font">General Terms</div>
                <ul className="policy-content-font">
                    <li>All refunds will be processed within 14 business days from the cancellation date.</li>
                    <li>Refunds will be issued through the original mode of payment.</li>
                    <li>Saveller Tours and Travels reserves the right to amend these policies at any time without prior notice.</li>
                </ul>
            </div>

            <div className="terms-heading">Terms and conditions</div>
            <div className="privacy-content merriweather-light ml-3">

                <div className="policy-content-font">Here's the revised version for Saveller Tours and Travels:</div>
                <div>Terms and Conditions</div>
                <div>Last updated: December 17, 2023</div>
                <div>
                    <div>
                        Welcome to the Saveller Tours and Travels website (the “Website”), a product of Saveller Tours and Travels (“Saveller,” “we,” “us,” or “our”). By accessing or using our Website, you agree to comply with and be bound by the following terms and conditions (the “Terms”). Please read these Terms carefully before using our Website.
                    </div>
                </div>
                <ul>
                    <li>
                        These terms and conditions outline the rules and regulations for the use of Saveller’s Website, located at saveller.com.
                    </li>
                    <li>
                        By accessing this website, we assume you accept these terms and conditions. Do not continue to use Saveller Tours and Travels if you do not agree to take all of the terms and conditions stated on this page.

                    </li>
                    <li>The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us” refers to our Company. “Party”, “Parties”, or “Us” refers to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of the provision of the Company’s stated services, in accordance with and subject to prevailing law. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.
                    </li>
                    <li>
                        <div>Use of Collected Information</div>
                        <div>a. Personal Information: We may use the personal information you provide to us for the following purposes:
                            <div className="website-as-follow">
                                <div>1. To provide and improve our services, including customer support and troubleshooting.
                                </div>
                                <div>
                                    2. To send you updates, newsletters, and promotional materials related to our products and services.
                                </div>
                                <div>
                                    3. To respond to your inquiries, feedback, or requests for assistance.
                                </div>
                            </div>
                        </div>
                        <div>
                            b. Usage Data: We may use the non-personal information collected for analytical purposes, to monitor and improve the functionality of our website, and to ensure the security of our systems.

                        </div>
                    </li>
                    <li>
                        <div> Data Retention</div>
                        We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
                        We will securely delete or anonymize your personal information when it is no longer needed.
                    </li>
                    <li>
                        <div> Information Sharing and Disclosure</div>
                        We may disclose your personal information to trusted third-party service providers who assist us in operating our website, conducting our business, or providing services to you. We take reasonable steps to ensure that these third parties process your personal information in compliance with applicable laws and regulations.

                    </li>
                    <li>
                        <div>Data Security</div>
                        We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security of your information.
                    </li>
                    <li>
                        <div> Your Rights</div>
                        You have the right to access, update, and correct inaccuracies in your personal information held by us. You may also request the deletion of your personal information, subject to any legal obligations we may have to retain certain data. To exercise these rights or for any privacy-related inquiries, please contact us using the information provided below.
                    </li>
                    <li>
                        <div> Third-Party Links</div>
                        Our website may contain links to third-party websites or services that are not operated or controlled by us. This Privacy Policy applies solely to our website. We encourage you to review the privacy policies of any third-party sites or services you visit.
                    </li>
                    <li>
                        <div>Changes to the Privacy Policy</div>
                        We reserve the right to modify or update this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised Privacy Policy on our website. We encourage you to review this Privacy Policy periodically for any updates.
                    </li>
                    <li>
                        <div>Contact Us</div>
                        If you have any questions or concerns regarding our Privacy Policy or the handling of your personal information, please contact us at [Contact Email]. We will make every effort to address and resolve your inquiries.
                    </li>
                    <li>
                        <div>Cookies</div>
                        We employ the use of cookies. By accessing Saveller Tours and Travels, you agreed to use cookies in agreement with Saveller’s Privacy Policy.
                        Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
                    </li>
                    <li>
                        <div>License</div>
                        Unless otherwise stated, Saveller and/or its licensors own the intellectual property rights for all material on Saveller Tours and Travels. All intellectual property rights are reserved. You may access this from Saveller Tours and Travels for your own personal use subjected to restrictions set in these terms and conditions.
                    </li>
                </ul>

                <div>You must not:</div>
                <ul>
                    <li> Republish material from Saveller Tours and Travels</li>
                    <li>Sell, rent or sub-license material from Saveller Tours and Travels </li>
                    <li>Reproduce, duplicate or copy material from Saveller Tours and Travels</li>
                    <li>Redistribute content from Saveller Tours and Travels</li>
                </ul>
                <div>
                    This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Free Terms and Conditions Generator.
                    Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Saveller does not filter, edit, publish, or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Saveller, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Saveller shall not be liable for the Comments or for any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
                </div>
                <div>
                    Saveller reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive, or cause a breach of these Terms and Conditions.
                </div>
                <div>You warrant and represent that:</div>
                <ul>
                    <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                    <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</li>
                    <li>
                        The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material which is an invasion of privacy;
                    </li>
                    <li>
                        The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.

                    </li>
                </ul>
                <div>You hereby grant Saveller a non-exclusive license to use, reproduce, edit, and authorize others to use, reproduce, and edit any of your Comments in any and all forms, formats, or media.
                    Hyperlinking to our Content
                </div>
                <div>
                    The following organizations may link to our Website without prior written approval:
                </div>
                <div className="website-as-follow">
                    <div >
                        1. Government agencies;
                    </div>
                    <div>
                        2. Search engines;
                    </div>
                    <div>
                        3. News organizations;
                    </div>
                </div>
                <div>
                    Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and
                    System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.
                    These organizations may link to our home page, to publications, or to other Website information so long as the link:
                    <div className="website-as-follow">
                        <div>
                            (a) is not in any way deceptive;
                        </div>
                        <div>
                            (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and
                        </div>
                        <div>
                            (c) fits within the context of the linking party’s site.
                        </div>
                    </div>
                    <div>We may consider and approve other link requests from the following types of organizations:</div>
                    <ul>
                        <li>Commonly-known consumer and/or business information sources;</li>
                        <li>dot.com community sites;</li>
                        <li>Associations or other groups representing charities;</li>
                        <li>Online directory distributors;</li>
                        <li>Internet portals;</li>
                        <li>Accounting, law and consulting firms; and</li>
                        <li>Educational institutions and trade associations.</li>
                    </ul>
                    <div>
                        <div>
                            <div>
                                We will approve link requests from these organizations if we decide that:</div>
                            <div className="website-as-follow">
                                <div>(a) the link would not make us look unfavorably to ourselves or to our accredited businesses; </div>
                                <div>(b) the organization does not have any negative records with us;</div>
                                <div>(c) the benefit to us from the visibility of the hyperlink compensates the absence of Saveller; and</div>
                                <div>(d) the link is in the context of general resource information.</div>
                            </div>

                            <div>These organizations may link to our home page so long as the link:</div>
                            <div className="website-as-follow">
                                <div>(a) is not in any way deceptive;</div>
                                <div>(b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and</div>
                                <div>(c) fits within the context of the linking party’s site.</div>
                            </div>
                            <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Saveller. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
                                Approved organizations may hyperlink to our Website as follows:</p>
                        </div>
                        <div className="website-as-follow">
                            <li>By use of our corporate name; or</li>
                            <li>By use of the uniform resource locator being linked to; or</li>
                            <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.
                            </li>
                        </div>
                    </div>
                    <div>
                        No use of Saveller’s logo or other artwork will be allowed for linking absent a trademark license agreement.
                        <div> iFrames</div>
                        <div>Without prior approval and written permission </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Privacy
