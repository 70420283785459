import React from 'react';
import { ReactComponent as Icon } from 'assets/icons/email.svg';

const EmailSvgIconComponent = ({ width = 30, height = 30, color = 'white' }) => {
  return (
    <Icon width={width} height={height} fill={color} />
  );
};

export default EmailSvgIconComponent;
