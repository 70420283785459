import React, { useEffect, useState } from 'react';
import './experiences-modal.css';
import ButtonClose from 'assets/icons/circle-close.svg';
import ArrowLeft from 'assets/icons/left-arrow.svg';
import ArrowRight from 'assets/icons/right-arrow.svg';

import ReactGA from 'react-ga4';

const ExperienceModal = ({ showModal, closeModal, image, currentIndex, updateCurrentIndex }) => {
    const [currentData, setCurrentData] = useState(image[currentIndex - 1]);

    useEffect(() => {
        setCurrentData(image[currentIndex]);
    }, [currentIndex, image]);


    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                closeModal();
            }
        };

        window.addEventListener('keydown', handleEsc);

        if (showModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            window.removeEventListener('keydown', handleEsc);
            document.body.classList.remove('modal-open');
        };
    }, [showModal, closeModal]);

    if (!showModal || !image) {
        return null;
    }

    const handleNext = (e) => {
        ReactGA.event({
            category: 'iterates',
            action: 'click ',
            label: `iterates next and previous `
        });

        e.stopPropagation();
        if (currentIndex < image.length - 1) {
            updateCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = (e) => {
        ReactGA.event({
            category: 'iterates',
            action: 'click ',
            label: `iterates next and previous `
        });
        e.stopPropagation();
        if (currentIndex > 0) {
            updateCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <div className="ep-modal-overlay" onClick={closeModal}>
            <div className="exp-navigation-arrows">
                <img src={ArrowLeft} alt="Previous" onClick={handlePrevious} className={`exp-arrow-left ${currentIndex === 0 ? 'disabled' : ''}`} />
                <img src={ArrowRight} alt="Next" onClick={handleNext} className={`exp-arrow-right ${currentIndex === image.length - 1 ? 'disabled' : ''}`} />
            </div>
            <div className='ep-modal-container' onClick={(e) => e.stopPropagation()}>
                <div className="expereince-detail-container">
                    <div className='popup-lines top-line-1'></div>
                    <div className='popup-lines top-line-2'></div>

                    <div className='popup-lines left-line-1'></div>

                    <div className='popup-lines right-line-1'> </div>
                    <img className="close-experience-button" src={ButtonClose} onClick={closeModal} alt="Close" />

                    <div className="expereince-detail">
                        <div className='ep-content-wrapper'>
                            <div className="ep-title-wrapper">
                                <h5 className='ep-title'>{currentData?.experienceName}</h5>
                            </div>
                            <div className='row experience-content-wrapper-row'>
                                <div className='col-md-6 col-xs-12 ep-image-container'>
                                    {currentData && (
                                        <img src={currentData.imgURL} alt={currentData.experienceName} className="ep-experience-image" />
                                    )}
                                </div>
                                <div className='col-md-6 col-xs-12 ep-description'>
                                    <span className='ep-text'>
                                        {currentData?.descriptions}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="bottom-left"></div>
                        <div className="bottom-right"></div>
                    </div>

                    <div className='popup-lines bottom-line-1'></div>
                    <div className='popup-lines bottom-line-2'></div>
                </div>
            </div>
        </div>
    );
};

export default ExperienceModal;
