import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './footer.css';

import Instagram from 'assets/icons/instagram.svg';
import YouTube from 'assets/icons/youtube.svg';
import emailjs from 'emailjs-com';
import FaceBook from 'assets/icons/download.svg';
import ReactGA from 'react-ga4';
import Spinner from 'components/spinner/spinner';

const Footer = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        message: ''
    });

    const [formStatus, setFormStatus] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));

        // Clear the error message for the current field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: ''
        }));
    };

    const ga = (labelName) => {
        ReactGA.event({
            category: 'footer ',
            action: 'click ',
            label: `${labelName} button click `
        });
    }

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = 'Name is required';
        }

        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid';
        }

        if (!formData.message) {
            newErrors.message = 'Message is required';
        }

        return newErrors;
    };

    const OpenYoutubeProfile = () => {
        const profileUrl = 'https://www.youtube.com/@Saveller';
        ga('youtube')
        window.open(profileUrl, '_blank');
    };


    const OpenFacebookProfile = () => {
        const profileUrl = 'https://www.facebook.com/people/Saveller/61554212098101/?mibextid=LQQJ4d';
        ga('facebook')
        window.open(profileUrl, '_blank');
    };


    const OpenInstagramProfile = () => {
        const profileUrl = 'https://www.instagram.com/save.ller';
        ga('instagram')
        window.open(profileUrl, '_blank');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        ga('send')
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length === 0) {
            setLoading(true);
            const serviceId = 'service_jri90ro';
            const templateId = 'template_omfaxur';
            const publicKey = 'yV0dp9nAmxrYGq7pr';

            const templateParam = {
                from_name: formData.name,
                from_email: formData.email,
                to_name: 'Saveller',
                message: formData.message,
            };

            emailjs.send(serviceId, templateId, templateParam, publicKey)
                .then((response) => {
                    setFormStatus('Message sent successfully!');
                    setLoading(false);
                    toast.success('Email sent successfully!');
                }, (err) => {
                    setLoading(false);
                    setFormStatus('Failed to send the message, please try again later.');
                    toast.error('Failed to send the email, please try again later.');
                });

            setFormData({
                name: '',
                email: '',
                message: ''
            });
            setErrors({});
        } else {
            setLoading(false);
            setErrors(validationErrors);
        }
    };

    return (
        <div id='contactUs' className="contactContainer">
            <div className="container py-1">
                <div className="row d-none d-md-flex">
                    <div className="col-md-8 contactSection">
                        <div className="container contactContainer-wrapper">
                            <h2 className="mb-4 cinzel-saveller text-uppercase" style={{
                                textAlign: 'justify',
                                paddingLeft: '16rem'
                            }}>Get in touch!</h2>
                            <form id='footer-form' onSubmit={handleSubmit}>
                                <div className="form-group my-2 row">
                                    <label htmlFor="name" className="col-sm-3 col-form-label text-left roboto-regular">
                                        Full name *
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Enter your name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                        {errors.name && <span className="text-danger" style={{ textAlign: 'justify', display: 'block' }}>{errors.name}</span>}
                                    </div>
                                </div>
                                <div className="form-group my-2 row">
                                    <label htmlFor="email" className="col-sm-3 col-form-label text-left roboto-regular">
                                        Email *
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Enter your email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && <span className="text-danger" style={{ textAlign: 'justify', display: 'block' }}>{errors.email}</span>}
                                    </div>
                                </div>
                                <div className="form-group my-2 row">
                                    <label htmlFor="message" className="col-sm-3 col-form-label text-left roboto-regular">
                                        Message *
                                    </label>
                                    <div className="col-sm-9">
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            rows="5"
                                            placeholder="Enter your message"
                                            value={formData.message}
                                            onChange={handleChange}
                                        ></textarea>
                                        {errors.message && <span className="text-danger" style={{ textAlign: 'justify', display: 'block' }}>{errors.message}</span>}
                                    </div>
                                </div>
                                <div className="form-group my-2 row justify-content-flex-start">
                                    <div className="col-sm-9">
                                        <button type="submit" className="contactButton roboto-regular">
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row">
                            <div className="container centered-content">
                                <h2 className="mb-3 addressContainer-alignments cinzel-saveller">ADDRESS</h2>
                                <span className='roboto-regular'>Saveller Tours and Travels</span>
                                <span className='roboto-regular'>Manikkadavu, Ulikkal, Iritty,</span>
                                <span className='roboto-regular'>Kannur, Kerala 670705</span>

                                <h2 className="mt-5 mb-3 cinzel-saveller">CONTACT</h2>
                                <div className=''>+91 70121 64577</div>
                                <div className=''>+91 90728 43980</div>
                                <div className='roboto-regular'>contact@saveller.com</div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 lower-div">
                        <div className="col-9 text-end roboto-regular">
                            <p className='lowerp'>&copy; 2024 by Saveller Tours and Travels. All rights reserved</p>
                            <p className='lowerp' style={{ textAlign: 'center', marginLeft: '34.9rem' }}>
                                <a href="/privacy-policy" className="footer-link">Privacy Policy</a> | <a href="/booking-cancellation-policy" className="footer-link">Booking & Cancellation Policy</a>
                            </p>
                        </div>
                        <div className="col-3 footer-social-buttons" id='footer-social-buttons-id'>
                            <a onClick={OpenInstagramProfile} target="_blank" rel="noopener noreferrer">
                                <img className='social-icons pe-2' src={Instagram} alt="Instagram" />
                            </a>
                            <a onClick={OpenYoutubeProfile} target="_blank" rel="noopener noreferrer">
                                <img className='social-icons pe-2' src={YouTube} alt="YouTube" />
                            </a>
                            <a onClick={OpenFacebookProfile} target="_blank" rel="noopener noreferrer">
                                <img src={FaceBook} alt="Facebook" className="facebook-logo-navbar pe-2" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row d-sm-none">
                    <div className="col-6" id='mobile-wrapper'>
                        <div className="container">
                            <h2 className="mb-3 addressContainer-alignments">ADDRESS</h2>
                            <div className='text-alignment'>Saveller Tours and Travels, No 9,</div>
                            <div className='text-alignment'>Kunnel Building, Ulikkal, Iritty,</div>
                            <div className='text-alignment'>Kannur, Kerala 670705</div>
                        </div>
                    </div>
                    <div className="col-6" id='mobile-wrapper'>
                        <div className="container">
                            <h2 className="mb-3 addressContainer-alignments">CONTACT</h2>
                            <div className='text-alignment'>+91 70121 64577</div>
                            <div className='text-alignment'>+91 90728 43980</div>
                            <div className='text-alignment'>contact@saveller.com</div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="container mt-4">
                            <h2 className="">FOLLOW US</h2>
                            <div className="footer-social-buttons">
                                <a onClick={OpenInstagramProfile} target="_blank" rel="noopener noreferrer">
                                    <img className='social-icons pe-2' src={Instagram} alt="Instagram" />
                                </a>
                                <a onClick={OpenYoutubeProfile} target="_blank" rel="noopener noreferrer">
                                    <img className='social-icons pe-2' src={YouTube} alt="YouTube" />
                                </a>
                                <a onClick={OpenFacebookProfile} target="_blank" rel="noopener noreferrer">
                                    <img src={FaceBook} alt="Facebook" className="facebook-logo-navbar pe-2" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 text-center">
                            <p> <span className='roboto-regular'>&copy; 2024</span> by Saveller Tours and Travels. All rights reserved</p>
                            <p>
                                <a href="/privacy-policy" className="footer-link">Privacy Policy</a> | <a href="/booking-cancellation-policy" className="footer-link">Booking & Cancellation Policy</a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <Spinner loading={loading} />
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="light"
            />
        </div >
    );
};

export default Footer;
