import React, { useState, useEffect, useRef } from "react";
import { Element } from 'react-scroll';
import "../../App.css";
import "container/home/home.style.css";
import Destination from "components/destinations/destination";
import CustomerSlider from "components/customer-slider/customerSlider";
import PartnerHotels from "components/partner/partner";
import whatsapp from 'assets/icons/whatsapp.svg';
import HomeSlider from "components/home-carousel/home-casousel";
import ContactUsModal from "components/contact-us-modal/contact-us-modal";

import Kerala1 from 'assets/landing-kerala/KERALA 2 (1).png'
import Kerala2 from 'assets/landing-kerala/KERALA 1 (1).png'
import North1 from 'assets/landing-north/MEGHALAYA.png'
import North2 from 'assets/landing-north/MEGHALAYA 2.png'
import ReactGA from 'react-ga4';

const imageArray = [
    { src: Kerala1, alt: "Kerala 1" },
    { src: Kerala2, alt: "Kerala 2" },
    { src: North1, alt: "Northeast 1" },
    { src: North2, alt: "Northeast 2" }
];

const Home = () => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const [isHomeSliderVisible, setIsHomeSliderVisible] = useState(false);
    const footerRef = useRef(null);
    const homeSliderRef = useRef(null);
    const isMobileView = window.innerWidth <= 767 ? true : false;

    const openModal = () => {
        setIsContactModalOpen(true);
        ReactGA.event({
            category: 'enquiry',
            action: 'click ',
            label: ` enquiry button is clicked `
        });
    };

    const closeModal = () => {
        setIsContactModalOpen(false);
        ReactGA.event({
            category: 'enquiry',
            action: 'click ',
            label: `close is clicked `
        });
    };

    const handleWhatsAppClick = () => {

        ReactGA.event({
            category: 'home',
            action: 'click ',
            label: `whatsapp button is clicked `
        });

        const phoneNumber = '+971586381929';
        const message = 'Hello, I want to inquire about your services.';

        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsFooterVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsHomeSliderVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (homeSliderRef.current) {
            observer.observe(homeSliderRef.current);
        }

        return () => {
            if (homeSliderRef.current) {
                observer.unobserve(homeSliderRef.current);
            }
        };
    }, []);

    return (
        <>
            <div ref={homeSliderRef}>
                <HomeSlider images={imageArray} />
            </div>
            <div className={`home-button-container ${isFooterVisible || isHomeSliderVisible ? 'hidden' : ''}`}>
                <img id='whatsUpIcon' src={whatsapp} onClick={handleWhatsAppClick} alt="WhatsApp" />
                <button id='enquiry-button' className="merriweather-light" onClick={openModal}>Enquiry</button>
            </div>

            <div className="about-us-container mt-3">
                <div className="Heading cinzel-saveller mb-2">About Us</div>
                <div className="merriweather-light content-font-size text-color" id="about-us-description">
                    Founded in 2023 by a passionate group from Kerala, India, Saveller is on a mission to revolutionize travel across the country. We are committed to simplifying complex itineraries and resolving your travel dilemmas, ensuring that you receive the exceptional experience you deserve for every penny spent.
                    Our goal is to maximize your savings without compromising on the comfort and quality of your journey.
                </div>
            </div>

            <section id="destination" style={!isMobileView ? { scrollMarginTop: "8em" } : {}}>
                <div className="Heading cinzel-saveller mt-2 mb-3">Tailor made Destination</div>
                <div className="content content-wrapper">
                    <div className="container" id='container-destination'>
                        <Destination />
                    </div>
                </div>
            </section>

            <section id="reviews" style={!isMobileView ? { scrollMarginTop: "8em" } : {}}>
                <section>
                    <div className="Heading cinzel-saveller mt-2 mb-3">Our customers</div>
                    <div className="content review-container">
                        <CustomerSlider />
                    </div>
                </section>
            </section>

            <div className="pt-3 partner-container" id="resort-heading-text">
                <div className="Heading cinzel-saveller  mt-2 mb-3">Our partner resorts & hotels</div>
                <div className="merriweather-light font-size-18 text-color" id="resort-description">
                    We've curated the finest resorts and hotels across Kerala to offer you a unique experience that blends exceptional hospitality with the rich culture of this enchanting region.
                </div>
                <div className="content" >
                    <PartnerHotels />
                </div>
            </div>
            <ContactUsModal isOpenContactModal={isContactModalOpen} onCloseContact={closeModal} />

            <footer ref={footerRef}>
            </footer>
        </>
    );
};

export default Home;
