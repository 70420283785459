import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import emailjs from '@emailjs/browser';

import 'components/contact-us-modal/contact-us-modal.css';
import ellipse from 'assets/Ellipse1.png';
import logo from 'assets/Logo.png';
import Close from 'assets/icons/circle-rounded.svg';
import Spinner from 'components/spinner/spinner';

const initialFormData = {
    package: 'Kerala with saveller',
    people: '',
    date: '',
    days: '3',
    flexible: false,
    mobile: '',
    email: '',
    notes: ''
};

const ContactUsModal = ({ isOpenContactModal, onCloseContact }) => {
    const [formData, setFormData] = useState(initialFormData);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27 && isOpenContactModal) {
                onCloseContact();
                setIsSubmitted(false);
            }
        };

        window.addEventListener('keydown', handleEsc);

        if (isOpenContactModal) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            window.removeEventListener('keydown', handleEsc);
            document.body.classList.remove('modal-open');
        };
    }, [isOpenContactModal, onCloseContact]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
        // Remove the error message for the field being typed in
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setFormErrors({});
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = (e) => {
        ReactGA.event({
            category: 'Enquiry',
            action: 'click',
            label: 'submit button click'
        });

        e.preventDefault();
        const errors = {};
        if (!formData.people) {
            errors.people = 'Number of people is required';
        }
        if (!formData.date) {
            errors.date = 'Date is required';
        }
        if (!formData.mobile) {
            errors.mobile = 'Mobile number is required';
        }
        if (formData.email && !validateEmail(formData.email)) {
            errors.email = 'Please enter a valid email address';
        }
        setFormErrors(errors);

        const serviceId = 'service_jri90ro';
        const templateId = 'template_omfaxur';
        const publicKey = 'yV0dp9nAmxrYGq7pr';

        const templateParams = {
            from_name: formData.email,
            from_email: formData.email,
            to_name: 'Saveller',
            message: `
                Package: ${formData.package}
                People: ${formData.people}
                Date: ${formData.date}
                Days: ${formData.days}
                Flexible: ${formData.flexible ? 'Yes' : 'No'}
                Mobile: ${formData.mobile}
                Notes: ${formData.notes}
            `
        };

        if (Object.keys(errors).length === 0) {
            setLoading(true);

            // Send email using EmailJS
            emailjs.send(serviceId, templateId, templateParams, publicKey)
                .then((response) => {
                    setLoading(false);
                    toast.success('Email sent successfully!');
                    setIsSubmitted(true);
                    resetForm();
                })
                .catch((err) => {
                    console.error('FAILED...', err);
                    setLoading(false);
                    setIsSubmitted(false);
                    toast.error('Failed to send the email, please try again later.');
                });
        } else {
            setLoading(false);
        }
    };

    if (!isOpenContactModal) {
        return null;
    }

    return (
        <div className={`modal-overlay ${isOpenContactModal ? 'open' : ''}`}>
            <div className="modal-container">
                <img src={ellipse} className="ellipse-image" alt="Ellipse" />
                <img src={logo} className="logo-image" alt="Logo" />
                <img src={Close} onClick={() => { onCloseContact(); setIsSubmitted(false); resetForm(); }} className="close-btn" alt="Close" />
                {!isSubmitted ? (
                    <form className="book-now-form" onSubmit={handleSubmit}>
                        <div className='row row-header'>
                            <label className='col-md-3 merriweather-regular font-side-13 contact-us-label' htmlFor="package">Which package are you interested in:</label>
                            <select id="package" name="package" className='col-md-3 merriweather-regular' value={formData.package} onChange={handleChange}>
                                <option value="" selected="selected">Select a package</option>
                                <option value="kerala">Kerala with Saveller</option>
                                <option value="northEast">North East with Saveller</option>
                            </select>
                        </div>
                        <div className='row row-header'>
                            <label className='col-md-3 merriweather-regular font-side-13 contact-us-label' htmlFor="people">No of People:</label>
                            <input className='col-md-3 roboto-regular' type="number" id="people" name="people" value={formData.people} onChange={handleChange} />
                            {formErrors.people && <div className="error-message">{formErrors.people}</div>}
                        </div>
                        <div className='row row-header input-date-wrapper' id="input-date-content">
                            <label className='col-md-3 roboto-regular font-side-13 contact-us-label' htmlFor="date">Date & Days:</label>
                            <input className='col-md-2 roboto-regular' type="date" id="date" name="date" placeholder="e.g., 14/06/2024 3 Days" value={formData.date} onChange={handleChange} />
                            {formErrors.date && <div className="error-message">{formErrors.date}</div>}
                            <select className='col-md-2 roboto-regular' id="days" name="days" value={formData.days} onChange={handleChange}>
                                <option value='3'>3 Days</option>
                                <option value='5'>5 Days</option>
                                <option value='7'>7 Days</option>
                                <option value='10'>10 Days</option>
                            </select>
                            <div className='col-md-3 flexible-container' id="flexible-input-check">
                                <label className='roboto-regular font-side-13 contact-us-label'>I am flexible:</label>
                                <input id="flexible" name="flexible" type="checkbox" checked={formData.flexible} onChange={handleChange} />
                                <label htmlFor="flexible"></label>
                            </div>
                        </div>
                        <div className='row row-header'>
                            <label className='col-md-3 roboto-regular font-side-13 contact-us-label' htmlFor="mobile">Mobile:</label>
                            <input className='col-md-3 roboto-regular' type="tel" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} />
                            {formErrors.mobile && <div className="error-message">{formErrors.mobile}</div>}
                        </div>
                        <div className='row row-header'>
                            <label className='col-md-3 merriweather-regular font-side-13 contact-us-label' htmlFor="email">Email:</label>
                            <input className='col-md-3 roboto-regular' type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                            {formErrors.email && <div className="error-message">{formErrors.email}</div>}
                        </div>
                        <div className='row row-header'>
                            <label className='col-md-3 merriweather-regular font-side-13 contact-us-label' htmlFor="notes">Notes:</label>
                            <textarea className='col-md-3 roboto-regular' id="notes" name="notes" rows="4" value={formData.notes} onChange={handleChange}></textarea>
                            <button type="submit" id="book-now-btn" className="icon-button merriweather-bold">
                                <span>SUBMIT</span>
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="success-message merriweather-regular">
                        <h3>We haven't asked you for a budget because we're dedicated to providing you with unbeatable prices!</h3>
                        <div style={{ marginTop: '3rem' }}>
                            <div>Thanks for choosing us! We will be in touch shortly</div>
                            <div id='success-company-name'>Saveller</div>
                        </div>
                    </div>
                )}
            </div>
            <Spinner loading={loading} />
            <ToastContainer position="top-right" autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} theme="light" />
        </div>
    );
};

export default ContactUsModal;
