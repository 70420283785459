import React, { useEffect, useState } from 'react';
import { Card, Carousel, Container, Row, Col } from 'react-bootstrap';
import "./customer.css";
import star from 'assets/icons/star.svg'

import CustomerGanesh from 'assets/customer/ganesh.jpg'
import CustomerSree from 'assets/customer/sree.jpg'
import CustomerSteve from 'assets/customer/steven.jpg'
import CustomerVinil from 'assets/customer/vinil.jpg'
import CustomerVysak from 'assets/customer/ysak.jpg'
import customerRD from 'assets/customer/RD.jpg'

const cardsData = [
    { id: 2, name: 'Vyshakh S', country: 'Australia', date: '', review: `I was on vacation and chose to book a 3-day Wayanad trip with Saveller. They gave us a really good deal on our package and made sure we were comfortable and happy throughout the trip.`, imageUrl: CustomerVysak },
    { id: 4, name: 'Steven P', country: 'Dubai', date: '', review: `True to their name, they saved us a lot of money! Booked our trip to Munnar, Alleppey, and Kochi effortlessly.`, imageUrl: CustomerSteve },
    { id: 1, name: 'Ganesh', country: 'Pune', date: '', review: `The trip was wonderful. All locations and programs chosen were absolutely breathtaking and a learning experience in themselves`, imageUrl: CustomerGanesh },
    { id: 3, name: 'Vinil PV', country: 'Bangalore', date: '', review: `We booked a 2-day trip to Wayanad with Saveller. They arranged a nice farmhouse in Muthanga in the middle of the forest. We had a very peaceful and refreshing time away from the chaos of the city.`, imageUrl: CustomerVinil },
    { id: 5, name: 'Sreerag Ev', country: 'Qatar', date: '', review: `We booked a 3-day trip with Saveller to Munnar and Alleppey.They made the process very easy and helped us make decisions.`, imageUrl: CustomerSree },
    { id: 6, name: 'Rohit D', country: 'Kolhapur', date: '', review: `They really offer the best rates without compromising on service.`, imageUrl: customerRD }
]

const renderStars = () => {
    return Array(5).fill().map((_, index) => (
        <img src={star} key={index} className='star-icon' alt="star" />
    ));
};

const CustomerSlider = () => {
    const [index, setIndex] = useState(0);
    const [reviewCardCount, setReviewCardCount] = useState(4);
    const [width, setWidth] = useState(window.innerWidth);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        setReviewCardCount(width < 1000 ? 1 : 4);
    }, [width]);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Container className='mx-3'>
            <Carousel activeIndex={index} fade interval={2000} onSelect={handleSelect} className='slideCustomer'>
                {cardsData.map((card, i) => (
                    i % reviewCardCount === 0 && (
                        <Carousel.Item key={i}>
                            <Row className="carousel-row">
                                {cardsData.slice(i, i + reviewCardCount).map((c, index) => (
                                    <Col id='review-card' key={index} className="carousel-col">
                                        <Card className='customerCard' id='customerCard-large'>
                                            <Card.Body className='primeryBg customerContainer'>
                                                <div className='row review-name'>
                                                    <div className='col-5'>
                                                        <Card.Img variant="top" src={c.imageUrl} className='profileImage' />
                                                    </div>
                                                    <div className='col-7'>
                                                        <div className='customerName merriweather-bold-900'>{c.name}</div>
                                                        <div className='customerCountry merriweather-bold-500'>{c.country}</div>
                                                        <div className='star-container'>
                                                            {renderStars()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='customerContainerChild'>
                                                    <div className='customerContent customer-review-font merriweather-light-200'>{c.review}</div>
                                                </div>
                                            </Card.Body>
                                        </Card>

                                        <Card className='customerCard' id='customerCard-mobile'>
                                            <Card.Body className='primeryBg customerContainer'>
                                                <div className='row review-name' style={{ marginTop: '1rem' }}>
                                                    <div className='col-3  mobile-col'>
                                                        <Card.Img variant="top" src={c.imageUrl} className='profileImage' />
                                                    </div>
                                                    <div className='col-9 customer-details'>
                                                        <div className='customerName merriweather-bold-900'>{c.name}</div>
                                                        <div className='customerCountry merriweather-regular'>{c.country}</div>
                                                        <div style={{ lineHeight: '16px' }}>
                                                            {renderStars()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row margin-bottom-text'>
                                                    <div className='col-12 pl-0'>
                                                        <div className='customerContainerChild'>
                                                            <div className='customerContent font-size-12 merriweather-light-200'>{c.review}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    )
                ))}
            </Carousel>
        </Container>
    );
};

export default CustomerSlider;
