import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';

import ButtonGroup from "components/filter/filter";
import ImageGallery from "components/image-gallary/ImageGallery";
import CustomSlider from "components/custom-slider/custom.slider";
import PartnerHotels from "components/partner/partner";
import CustomerSlider from "components/customer-slider/customerSlider";
import HomeSlider from "components/home-carousel/home-casousel";
import ContactUsModal from "components/contact-us-modal/contact-us-modal";

import whatsapp from 'assets/icons/whatsapp.svg';

import Wayanad from 'assets/itenaries/waynad.jpg'
import Kochi from 'assets/itenaries/kochi.jpeg'
import Munnar from 'assets/itenaries/munnar.jpeg'
import Alappuzha from 'assets/itenaries/alappy.jpeg'
import Thekkady from 'assets/itenaries/thekkadi.jpeg'
import Vagamon from 'assets/itenaries/vagamon.jpeg'
import Varkkala from 'assets/itenaries/varkala.jpeg'

import north1 from 'assets/itenaries-nort/1.jpeg'
import north2 from 'assets/itenaries-nort/2.jpeg'
import north3 from 'assets/itenaries-nort/3.jpeg'
import north4 from 'assets/itenaries-nort/4.jpeg'
import north5 from 'assets/itenaries-nort/5.jpeg'
import north6 from 'assets/itenaries-nort/6.jpeg'
import north7 from 'assets/itenaries-nort/7.jpeg'
import north8 from 'assets/itenaries-nort/8.jpeg'
import north9 from 'assets/itenaries-nort/9.jpeg'
import north10 from 'assets/itenaries-nort/10.jpeg'

import PackageKochi from 'assets/packages/package-kochin.jpeg'
import PackageMunnar from 'assets/packages/package-munnar.jpeg'
import PackageThekkady from 'assets/packages/package-thekkady.jpeg'
import Packagewagamon from 'assets/packages/package-wagamon.jpeg'
import PackageAlappy from 'assets/packages/package-alappuzha.jpeg'
import PackageVarkkala from 'assets/packages/package-varkkala.jpg'
import PackageKerala from 'assets/packages/kerala.jpeg'

import PackageNorth1 from 'assets/packages/north1.jpeg'
import PackageNorth2 from 'assets/packages/north2.jpeg'

import "./style.css";
import "container/home/home.style.css";

import Kerala1 from 'assets/landing-kerala/KERALA 1 (1).png'
import Kerala2 from 'assets/landing-kerala/KERALA 2 (1).png'
import North1 from 'assets/landing-north/MEGHALAYA.png'
import North2 from 'assets/landing-north/MEGHALAYA 2.png'

import ReactGA from 'react-ga4';

const Packages = (props) => {
    const location = useLocation();

    const keralaPackageDescriptions = {
        name: 'Gods own country',
        description: `Embark on a journey to Kerala, the heart of India's tropical splendor, with Savellers. Known as "God's Own Country," Kerala invites you to experience its lush backwaters, pristine beaches, and verdant hills. With Savellers,
                    your adventure is crafted to be as unique as you are, blending personalized experiences with affordability.`
    }

    const northEastDescription = {
        name: 'Seven Sisters',
        description: `Explore Assam's tea gardens, Kaziranga's wildlife, and Guwahati's culture, then discover Meghalaya's living root bridges, Mawlynnong village, and Shillong's landscapes with Saveller's tailored tour packages. Immerse yourself in Northeastern charm, from Assamese cuisine delights to Meghalaya's trekking adventures, ensuring a memorable journey of natural beauty and cultural richness with Saveller.`
    }

    const packageDetails = {

        kerala: [
            {
                "title": "ALLEPPEY  THEKKADY  VAGAMON",
                "url": PackageThekkady,
                "duration": "3N 2D",
                "totalDays": "3",
                "id": 1,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Thekkady,
                        "location": "Arrival Thekkady",
                        "activities": [
                            "Cultural activities",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Vagamon,
                        "location": "Vagamon- Alleppey",
                        "activities": [
                            " Periyar boat ride",
                            "Pine forest & meadows",
                            "En route to Alleppey",
                            "Sunset",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Alappuzha,
                        "location": "Alappuzha",
                        "activities": [
                            "Kayaking / Shikara ride",
                            "Toddy shop visit",
                            "Departure"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 3 Days",
                    "Accommodation",
                    "Breakfast for all 2 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "THEKKADY  MUNNAR",
                "duration": "2N 3D",
                "url": PackageMunnar,
                "totalDays": "3",
                "id": 2,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Thekkady,
                        "location": "Arrival",
                        "activities": [
                            "Cultural activities",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Munnar,
                        "location": "Thekkady- Munnar",
                        "activities": [
                            " Periyar boat ride",
                            "Pine forest & meadows",
                            "En route to Munnar0",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Munnar,
                        "location": "Kolukumalai trek (optional)",
                        "activities": [
                            "Mattupetty Dam",
                            "Pookode dam",
                            "Munnar Market",
                            "Departure"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 3 Days",
                    "Accommodation",
                    "Breakfast for all 2 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "MUNNAR  VAGAMON ALLEPPEY",
                "duration": "2N 3D",
                "url": Packagewagamon,
                "totalDays": "3",
                "id": 3,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Kochi,
                        "location": "Kochi arrival",
                        "activities": [
                            "Mattupetty Dam visit",
                            "Tea estate visit",
                            "Munnar Market visit",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Vagamon,
                        "location": "Vagamon - Alleppey",
                        "activities": [
                            "Kolukkumalai Sunrise (Recommended)",
                            "En route to Vagamon",
                            "Pine forest & meadows",
                            "En route to Alleppey",
                            "Sunset"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Alappuzha,
                        "location": "Alleppey",
                        "activities": [
                            "Kayaking/Shikara ride",
                            "Houseboat cruise"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 3 Days",
                    "Accommodation",
                    "Breakfast for all 2 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "ALLEPPEY VAGAMON MUNNAR",
                "totalDays": "3",
                "url": PackageAlappy,
                "duration": "2N 3D",
                "id": 4,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Kochi,
                        "location": "Kochi arrival",
                        "activities": [
                            "Houseboat cruise",
                            "Evening Sunset",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Alappuzha,
                        "location": "Alleppey- Munnar",
                        "activities": [
                            "Kayaking/ Shikara ride",
                            "En route to Vagamon",
                            "Pine forest & meadows",
                            "En route to Munnar",
                            "Sunset"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Munnar,
                        "location": "Munnar",
                        "activities": [
                            "Kolukkumalai sunrise"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 3 Days",
                    "Accommodation",
                    "Breakfast for all 2 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "MUNNAR  VAGAMON  ALLEPPEY",
                "totalDays": "4",
                "duration": "4D 3N",
                "url": Packagewagamon,
                "id": 5,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Munnar,
                        "location": "Munnar",
                        "activities": [
                            "Arrival",
                            "TEA ESTATE",
                            "Mattupetty dam (optional)"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Vagamon,
                        "location": "Vagamon",
                        "activities": [
                            "Kolukumalai sunrise(optional)",
                            "Pine Forest & Meadows",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Alappuzha,
                        "location": "Alappuzha",
                        "activities": [
                            "Houseboat cruise & night stay",
                            "Cultural activities",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": Alappuzha,
                        "location": "Alappizha",
                        "activities": [
                            "Kayaking",
                            "Departure "
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 4 Days",
                    "Accommodation",
                    "Breakfast for all 3 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "MUNNAR  VAGAMON  ALLEPPEY",
                "duration": "4D 3N",
                "totalDays": "4",
                "id": 6,
                "url": Packagewagamon,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Munnar,
                        "location": "Munnar",
                        "activities": [
                            "Arrival",
                            "TEA ESTATE",
                            "Mattupetty dam (optional)"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Vagamon,
                        "location": "Vagamon",
                        "activities": [
                            "Kolukumalai sunrise(optional)",
                            "Pine Forest & Meadows",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Alappuzha,
                        "location": "Alappuzha",
                        "activities": [
                            "Houseboat cruise & night stay",
                            "Cultural activities",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": Alappuzha,
                        "location": "Alappizha",
                        "activities": [
                            "Kayaking",
                            "Departure "
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 4 Days",
                    "Accommodation",
                    "Breakfast for all 3 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "MUNNAR  THEKKADY  ALLEPPEY",
                "duration": "4D 3N",
                "totalDays": "4",
                "url": PackageMunnar,
                "id": 7,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Munnar,
                        "location": "Munnar",
                        "activities": [
                            "Arrival",
                            "TEA ESTATE",
                            "Mattupetty dam (optional)",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Thekkady,
                        "location": "Thekkady",
                        "activities": [
                            "Kolukumalai sunrise(optional)",
                            "Pine Forest & Meadows",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Alappuzha,
                        "location": "Alappuzha",
                        "activities": [
                            "Shikara ride/ Houseboat cruise",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": Alappuzha,
                        "location": "Alappizha",
                        "activities": [
                            "Kayaking",
                            "Departure "
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 4 Days",
                    "Accommodation",
                    "Breakfast for all 3 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "MUNNAR  VAGAMON  THEKKADY  ALLEPPEY",
                "duration": "4D 3N",
                "totalDays": "4",
                "url": PackageThekkady,
                "id": 8,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Munnar,
                        "location": "Munnar",
                        "activities": [
                            "Arrival",
                            "TEA ESTATE",
                            "Mattupetty dam (optional)"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Thekkady,
                        "location": "Thekkady",
                        "activities": [
                            "Kolukumalai sunrise(optional)",
                            "Pine Forest & Meadows",
                            "Elephant ride, Cultural activities(optional)"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Alappuzha,
                        "location": "Alappuzha",
                        "activities": [
                            "Houseboat cruise & night stay",
                            "Fullbody massage (optional)",
                            "Cultural activities",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": Alappuzha,
                        "location": "Alappizha",
                        "activities": [
                            "Kayaking",
                            "Departure "
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 4 Days",
                    "Accommodation",
                    "Breakfast for all 3 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "THEKKADY  VAGAMON  MUNNAR  ALLEPPEY",
                "duration": "5D - 4N",
                "url": PackageMunnar,
                "totalDays": "5",
                "id": 9,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Munnar,
                        "location": "Munnar",
                        "activities": [
                            "Arrival",
                            "Spice garden visit",
                            "Cultural activities",
                            "Elephant ride",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Vagamon,
                        "location": "Vagamon - Munnar",
                        "activities": [
                            "Periyar boat ride",
                            "Pine forest & meadows",
                            "En route to Munnar",
                            "Mattupetty Dam",
                            "Munnar market"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Munnar,
                        "location": "Munnar - Alleppey",
                        "activities": [
                            "Kolukkumalai sunrise",
                            "En route to alleppey",
                            "Coir museum",
                            "Cultural activities",
                            "Sunset cruise"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": Alappuzha,
                        "location": "Alappizha",
                        "activities": [
                            "Sunrise Kayaking",
                            "Village Tour",
                            "Houseboat cruise and overnight stay",
                            "Relax Fullbody massage"
                        ]
                    },
                    {
                        "day": "#DAY 5",
                        "image": Alappuzha,
                        "location": "Alappizha",
                        "activities": [
                            "Toddy shop",
                            "Pathiramanal bird sanctuary",
                            "Departure"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 5 Days",
                    "Accommodation",
                    "Breakfast for all 4 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "FORT KOCHI  THEKKADY  MUNNAR  ALLEPPEY",
                "duration": "5D - 4N",
                "url": PackageAlappy,
                "totalDays": "5",
                "id": 10,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Kochi,
                        "location": "FortKochi",
                        "activities": [
                            "Arrival",
                            "Jew street",
                            "Synagogue",
                            "Art gallery",
                            "Water metro ride",
                            "Chinese fishing net",
                            "Sunset Fort Kochi Beach"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Thekkady,
                        "location": "Thekkady",
                        "activities": [
                            "Cooking class",
                            "Spice garden visit",
                            "Massage",
                            "Elephant ride",
                            "Cultural activities"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Munnar,
                        "location": "Thekkady - Munnar",
                        "activities": [
                            "Periyar boat ride",
                            "En route to Munnar",
                            "Tea estate visit",
                            "Mattupetty Dam visit",
                            "Munnar market & shopping"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": Munnar,
                        "location": "Munnar",
                        "activities": [
                            "Kolukkumalai sunrise",
                            "En route to Alleppey",
                            "Pathiramanal bird sanctuary",
                            "Coir Museum",
                            "Sunset",
                            "Cultural activities"
                        ]
                    },
                    {
                        "day": "#DAY 5",
                        "image": Alappuzha,
                        "location": "Alappizha",
                        "activities": [
                            "Sunrise kayaking",
                            "Village tour",
                            "Toddy shop",
                            "Departure"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 5 Days",
                    "Accommodation",
                    "Breakfast for all 4 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "THEKKADY   MUNNAR  ALLEPPEY",
                "duration": "5D - 4N",
                "url": PackageMunnar,
                "totalDays": "5",
                "id": 11,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Thekkady,
                        "location": "Thekkady",
                        "activities": [
                            "Arrival",
                            "Cultural activities",
                            "Elephant ride",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Munnar,
                        "location": "Thekkady  Munnar",
                        "activities": [
                            "Periyar boat ride",
                            "Pine forest & meadows",
                            "En route to Munnar"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Munnar,
                        "location": "Munnar  Alleppey",
                        "activities": [
                            "Kolukkumalai sunrise",
                            "Mattupetty Dam",
                            "Munnar Market"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": Alappuzha,
                        "location": "Munnar  Alleppey",
                        "activities": [
                            "Houseboat cruise",
                            "Sunset at Beach"
                        ]
                    },
                    {
                        "day": "#DAY 5",
                        "image": Alappuzha,
                        "location": "Alappizha",
                        "activities": [
                            "Kayaking/ Shikara ride",
                            "Departure"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 5 Days",
                    "Accommodation",
                    "Breakfast for all 4 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "THEKKADY  VAGAMON  MUNNAR  ALLEPPEY  KOCHI",
                "duration": "6D 5N",
                "url": PackageKochi,
                "totalDays": "6",
                "id": 12,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Thekkady,
                        "location": "Thekkady",
                        "activities": [
                            "Arrival",
                            "Spice garden visit",
                            "Cultural activities",
                            "Elephant ride",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Vagamon,
                        "location": "Thekkady  Vagamon",
                        "activities": [
                            "Periyar boat ride",
                            "Pine forest & meadows",
                            "En route to Munnar"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Munnar,
                        "location": "Munnar",
                        "activities": [
                            "Kolukkumalai sunrise",
                            "Mattupetty Dam",
                            "Munnar Market"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": Alappuzha,
                        "location": "Alappizha",
                        "activities": [
                            "Houseboat cruise and overnight stay",
                            "Sunset at beach"
                        ]
                    },
                    {
                        "day": "#DAY 5",
                        "image": Kochi,
                        "location": "Alleppey  Kochi",
                        "activities": [
                            "Kayaking/ Shikara ride",
                            "En route to Kochi",
                            "Jew street visit",
                            "Synagogue",
                            "Chinese Fishing net",
                            "Sunset at Marine Drive"
                        ]
                    },
                    {
                        "day": "#DAY 6",
                        "image": Kochi,
                        "location": "Kochi",
                        "activities": [
                            "Water metro ride",
                            "Toddy shop",
                            "Departure"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 6 Days",
                    "Accommodation",
                    "Breakfast for all 5 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "SOUTHERN KERLALA",
                "duration": "7D 6N",
                "url": PackageVarkkala,
                "totalDays": "7",
                "id": 14,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Kochi,
                        "location": "FortKochi",
                        "activities": [
                            "Arrival",
                            "Jew street",
                            "Synagogue",
                            "Art gallery",
                            "Water metro ride",
                            "Chinese fishing net",
                            "Sunset Fort Kochi Beach"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Thekkady,
                        "location": "Thekkady",
                        "activities": [
                            "En route to Thekkady",
                            "Cooking class",
                            "Spice garden visit",
                            "Massage",
                            "Elephant ride",
                            "Cultural activities"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Munnar,
                        "location": "Thekkady - Munnar",
                        "activities": [
                            " Periyar boat ride",
                            "En route to Munnar",
                            "Tea estate visit",
                            "Mattupetty Dam visit",
                            "Munnar market & shopping"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": Munnar,
                        "location": "Munnar",
                        "activities": [
                            "Kolukkumalai sunrise",
                            "En route to Alleppey",
                            "Pathiramanal bird sanctuary",
                            "Coir Museum",
                            "sunset at beach",
                            "cultural activities"
                        ]
                    },
                    {
                        "day": "#DAY 5",
                        "image": Alappuzha,
                        "location": "Alleppey",
                        "activities": [
                            "Sunrise kayaking",
                            "Village tour",
                            "Toddy shop",
                            "Houseboat",
                            "En route to Monroe"
                        ]
                    },
                    {
                        "day": "#DAY 6",
                        "image": Varkkala,
                        "location": "Varkala",
                        "activities": [
                            "Mangrove island kayaking",
                            "Temple",
                            "Varkala beach sunset"
                        ]
                    },
                    {
                        "day": "#DAY 7",
                        "image": Varkkala,
                        "location": "Varkala",
                        "activities": [
                            "Jadayu rock",
                            "Departure"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 7 Days",
                    "Accommodation",
                    "Breakfast for all 6 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "KERALA",
                "duration": "10D  9N",
                "url": PackageKerala,
                "totalDays": "10",
                "id": 15,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": Wayanad,
                        "location": "Wayanad",
                        "activities": [
                            "Arrival Kannur airport",
                            "Banasura dam"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": Wayanad,
                        "location": "Wayanad",
                        "activities": [
                            "Edakkal caves",
                            "En uru tribal village visit"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": Kochi,
                        "location": "Wayanad  Kochi",
                        "activities": [
                            "Bandipur safari",
                            "En route to Kochi"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": Kochi,
                        "location": "FortKochi",
                        "activities": [
                            " Jew street",
                            "Synagogue",
                            "Art gallery",
                            "Water metro ride",
                            "Chinese fishing net",
                            "Sunset Fort Kochi Beach"
                        ]
                    },
                    {
                        "day": "#DAY 5",
                        "image": Thekkady,
                        "location": "Thekkady",
                        "activities": [
                            "En route to Thekkady",
                            "Cooking class",
                            "Spice garden visit",
                            "Massage",
                            "Elephant ride",
                            "Cultural activities"
                        ]
                    },
                    {
                        "day": "#DAY 6",
                        "image": Munnar,
                        "location": "Thekkady  Munnar",
                        "activities": [
                            "Periyar boat ride",
                            "En route to Munnar",
                            "Tea estate visit",
                            "Mattupetty Dam visit",
                            "Munnar market & shopping"
                        ]
                    },
                    {
                        "day": "#DAY 7",
                        "image": Munnar,
                        "location": "Munnar",
                        "activities": [
                            "Kolukkumalai sunrise",
                            "En route to Alleppey",
                            "Pathiramanal bird sanctuary",
                            "Coir Museum",
                            "sunset at beach",
                            "cultural activities"
                        ]
                    },
                    {
                        "day": "#DAY 8",
                        "image": Alappuzha,
                        "location": "Alleppey",
                        "activities": [
                            "Sunrise kayaking",
                            "Village tour",
                            "Toddy shop",
                            "houseboat",
                            "En route to Varkala"
                        ]
                    },
                    {
                        "day": "#DAY 9",
                        "image": Varkkala,
                        "location": "Varkala",
                        "activities": [
                            "Monroe Island",
                            "Temple",
                            "Varkala beach sunset"
                        ]
                    },
                    {
                        "day": "#DAY 10",
                        "image": Varkkala,
                        "location": "Varkala",
                        "activities": [
                            "Jadayu rock",
                            "Departure "
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 10 Days",
                    "Accommodation",
                    "Breakfast for all 9 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            }
        ],
        northeast: [
            {
                "title": "Meghalaya",
                "duration": "7D 6N",
                "url": PackageNorth2,
                "totalDays": "7",
                "id": 1,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": north1,
                        "location": "Guwahati ",
                        "activities": [
                            "Arrival",
                            "Drive to Sohra",
                            "Check in",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": north2,
                        "location": "Sohra",
                        "activities": [
                            "Drive to Rangkhylliaw bridge",
                            "Kongthong the Whistling Village Visit",
                            "Demonstration in the village lunch",
                            "Back to hotel",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": north3,
                        "location": "Sohra",
                        "activities": [
                            "Dawki the crystal clear water", "boating there and water sports activities",
                            "Mawlynnong the cleanliness village in Asia",
                            "Back to hotel",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": north4,
                        "location": "Sohra",
                        "activities": [
                            "Nohkalikai falls",
                            "Arwah caves",
                            "Ramkrishna mission",
                            "Mawsmai Cave and seven sister falls",
                            "Back to hotel"
                        ]
                    },
                    {
                        "day": "#DAY 5",
                        "image": north5,
                        "location": "Sohra",
                        "activities": [
                            "Prut falls",
                            "Lyngksiar falls",
                            "Mawsawa falls",
                            "Back to hotel",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 6",
                        "image": north6,
                        "location": "Sohra",
                        "activities": [
                            "Double decker rootbridge or Umkar rootbridge",
                            "kynrem falls",
                            "Back to hotel",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 7",
                        "image": north7,
                        "location": "Sohra",
                        "activities": [
                            "Check out", "Departure",
                            "Airport drop off"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 7 Days",
                    "Accommodation",
                    "Breakfast for all 6 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            },
            {
                "title": "Assam- Meghalaya",
                "duration": "10D 9N",
                "url": PackageNorth1,
                "totalDays": "10",
                "id": 2,
                "days": [
                    {
                        "day": "#DAY 1",
                        "image": north4,
                        "location": "Guwahati",
                        "activities": [
                            "Arrival",
                            "Kamakhya temple visit"
                        ]
                    },
                    {
                        "day": "#DAY 2",
                        "image": north7,
                        "location": "Pobitora",
                        "activities": [
                            "Pobitora elephant safari",
                            "Brahmaputra experience"
                        ]
                    },
                    {
                        "day": "#DAY 3",
                        "image": north10,
                        "location": "Pobitora",
                        "activities": [
                            "Jeep safari",
                            "Drive to Sohra",
                            "Umiam Lake viewpoint",
                            "Mawphlang Sacred Forest guided tour"
                        ]
                    },
                    {
                        "day": "#DAY 4",
                        "image": north9,
                        "location": "Sohra",
                        "activities": [
                            "Drive to Rangkhylliaw bridge",
                            "Kongthong the Whistling Village Visit",
                            "Demonstration in the village lunch",
                            "Back to hotel",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 5",
                        "image": north5,
                        "location": "Sohra",
                        "activities": [
                            "Dawki the crystal clear water,", "boating there and water sports activities",
                            "Mawlynnong the cleanliness village in Asia ",
                            "Back to hotel",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 6",
                        "image": north7,
                        "location": "Sohra",
                        "activities": [
                            "Nohkalikai falls",
                            "Arwah caves",
                            "Ramkrishna mission",
                            "Mawsmai cave n seven sister falls",
                            "Back to hotel"
                        ]
                    },
                    {
                        "day": "#DAY 7",
                        "image": north2,
                        "location": "Sohra",
                        "activities": [
                            "Prut falls",
                            "Lyngksiar falls ",
                            "Mawsawa falls",
                            "Back to hotel",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 8",
                        "image": north8,
                        "location": "Sohra",
                        "activities": [
                            "Double decker rootbridge or Umkar rootbridge",
                            "kynrem falls",
                            "Back to hotel",
                            "Rest"
                        ]
                    },
                    {
                        "day": "#DAY 9",
                        "image": north4,
                        "location": "Sohra",
                        "activities": [
                            "Crest of Nohkalikai hiking",
                            "Local market visit",
                        ]
                    },
                    {
                        "day": "#DAY 10",
                        "image": north10,
                        "location": "Sohra",
                        "activities": [
                            "Check out",
                            "Departure",
                            "Airport drop off"
                        ]
                    }
                ],
                "inclusions": [
                    "Guide for 10 Days",
                    "Accommodation",
                    "Breakfast for all 9 days",
                    "Transport",
                    "Sightseeing tickets if applicable in the package"
                ]
            }
        ]

    };

    const imageArray = {
        kerala: [
            { src: Kerala1, alt: "Kerala 1" },
            { src: Kerala2, alt: "Kerala 2" },
        ],
        northeast: [
            { src: North1, alt: "Kerala 1" },
            { src: North2, alt: "Kerala 2" },
        ]
    };

    const packageLocation = location.pathname.replace(/\//g, '');
    const [filter, setFilter] = useState({ min: 0, max: Infinity });
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [description, setDescription] = useState({});
    const [filteredPackageDetails, setFilteredPackageDetails] = useState([]);
    const [carouselImages, setCarouselImages] = useState([]);
    const [index, setIndex] = useState(0);
    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const [isKerala, setIsKerala] = useState(false);
    const [isNortheast, setIsNorthEast] = useState(false);
    const [isHomeSliderVisible, setIsHomeSliderVisible] = useState(false);
    const footerRef = useRef(null);
    const homeSliderRef = useRef(null);
    const isMobileView = window.innerWidth <= 767 ? true : false;

    const openContactModal = () => {
        ReactGA.event({
            category: 'package ',
            action: 'click ',
            label: `enquiery click `
        });
        setIsContactModalOpen(true);
    };

    const closeNavContactModal = () => {
        setIsContactModalOpen(false);
        ReactGA.event({
            category: 'package ',
            action: 'click ',
            label: `enquery close button click `
        });
    };

    useEffect(() => {
        if (packageLocation === 'kerala') {
            setDescription(keralaPackageDescriptions);
            setFilteredPackageDetails(packageDetails.kerala);
            setCarouselImages(imageArray.kerala);
            setIsNorthEast(false);
            setIsKerala(true);
        } else if (packageLocation === 'northeast') {
            setDescription(northEastDescription);
            setFilteredPackageDetails(packageDetails.northeast);
            setCarouselImages(imageArray.northeast);
            setIsKerala(false);
            setIsNorthEast(true);
        }
    }, [packageLocation]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsFooterVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsHomeSliderVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (homeSliderRef.current) {
            observer.observe(homeSliderRef.current);
        }

        return () => {
            if (homeSliderRef.current) {
                observer.unobserve(homeSliderRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const details = packageDetails[packageLocation]?.filter(detail =>
            detail.totalDays >= filter.min && detail.totalDays <= filter.max
        );
        setFilteredPackageDetails(details);
    }, [filter, packageLocation]);

    const handleCurrentObjectIndex = (index) => {
        setIndex(index);
    };

    return (
        <div className="package-top-spacing">
            <div ref={homeSliderRef}>
                <HomeSlider images={carouselImages} showHeader={true} packageLocationFromURL={packageLocation} />
            </div>
            <div>
                <div className="Heading cinzel-saveller heading-spacing">{description.name}</div>
                <div className="merriweather-light content-font-size text-color content-horiz-spacing">
                    {description.description}
                </div>
            </div>

            <div>
                <div className="Heading cinzel-saveller heading-spacing" id="packages" style={!isMobileView ? { scrollMarginTop: "4em" } : {}}>Packages</div>
                <div>
                    <ButtonGroup onFilterChange={setFilter} isKerala={isKerala} />
                </div>
                <div>
                    <ImageGallery images={filteredPackageDetails} isMobileView={isMobileView} currentObjectItemIndex={handleCurrentObjectIndex} />
                </div>
                <div className={`home-button-container ${isFooterVisible || isHomeSliderVisible ? 'hidden' : ''}`}>
                    <img id='whatsUpIcon' src={whatsapp} alt="WhatsApp" />
                    <button id='enquiry-button' className="merriweather-light" onClick={openContactModal}>Enquiry</button>
                </div>
                <div className="content merriweather-light d-large-none">
                    Not finding the option you are looking for? Get in touch with us to build your dream holiday
                </div>
                <div className="content merriweather-light d-small-none">
                    <div>
                        Not finding the option you are looking for?</div>
                    <div>Get in touch with us to build your dream holiday</div>
                </div>
                <div className="contact-us-btn primaryButton merriweather-light" onClick={openContactModal}>
                    Contact us
                </div>
            </div>

            <div>
                <div className="Heading cinzel-saveller heading-spacing" id="experiences" style={!isMobileView ? { scrollMarginTop: "4em" } : {}}>Experiences</div>
                <div className="experience-outer-container">
                    <CustomSlider isKerala={isKerala} />
                </div>
            </div>

            <section id="reviews" style={!isMobileView ? { scrollMarginTop: "8em" } : {}}>
                <div className="Heading cinzel-saveller heading-spacing">Our customers</div>
                <div className="content review-container">
                    <CustomerSlider />
                </div>
            </section>

            <div className="mb-5">
                <div className="Heading cinzel-saveller" id="resort-heading-text">Our partner resorts & hotels</div>
                <div className="content" id="partner-content-item-wrapper">
                    <PartnerHotels isKerala={isKerala} isNortheast={isNortheast} />
                </div>
            </div>
            <footer ref={footerRef}>
                {/* Footer content goes here */}
            </footer>

            <ContactUsModal isOpenContactModal={isContactModalOpen} onCloseContact={closeNavContactModal} />
        </div>
    );
};

export default Packages;
