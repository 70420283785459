import React from 'react';
import "./comming-soon.css";


const ComingSoon = () => {


    return (
        <div >
            <div className="content merriweather-light margin-new">
                Coming Soon
            </div>

        </div>
    );
};

export default ComingSoon;
