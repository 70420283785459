import React from 'react';
import { useNavigate } from 'react-router-dom';
import Kerala from 'assets/kerala.jpg';
import NorthEast from 'assets/northeast.jpg';
import Ledakh from 'assets/leh-ledakh.png';
import Goa from 'assets/goa.jpg';
import Himachal from 'assets/himachal.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './destination.css';

function Destination() {
    const items = [
        { src: Kerala, alt: 'Kerala', text: 'Kerala', url: 'kerala' },
        { src: NorthEast, alt: 'North East', text: 'North East', url: 'northEast' },
        { src: Goa, alt: 'GOA', text: 'GOA', url: 'goa' },
        // { src: Ledakh, alt: 'LEH LADAKH', text: 'LEH LADAKH' },
        { src: Himachal, alt: 'HIMACHAL', text: 'HIMACHAL', url: 'himachal' },
    ];

    const navigate = useNavigate();

    const handleClick = (item) => {
        navigate(`/${item.url.toLowerCase()}`);
    };

    return (
        <div className="container destination-container">
            <div className="row">
                {items.map((item, index) => (
                    <div key={index} className="col-lg-3 col-md-4 col-sm-6 destination-column" onClick={() => handleClick(item)} >
                        <div className="destination-item">
                            <img src={item.src} alt={item.alt} className="img-fluid" />
                            <p className="centered-text">{item.text}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Destination;
