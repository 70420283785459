import React, { useState, useEffect } from 'react';
import './modal.css';
import ReactGA from 'react-ga4';

import TourPackage from 'container/Itineraries/itineraries';
import ButtonClose from 'assets/icons/circle-close.svg';
import ArrowLeft from 'assets/icons/left-arrow.svg';
import ArrowRight from 'assets/icons/right-arrow.svg';

const Modal = ({ showModal, closeModal, data, notifyParent, currentIndex, updateCurrentIndex }) => {
    const [currentData, setCurrentData] = useState(data[currentIndex]);

    useEffect(() => {
        setCurrentData(data[currentIndex]);
    }, [currentIndex, data]);

    if (!showModal) return null;

    const handleEnquire = () => {
        closeModal();
        notifyParent();
    };

    const closeNavContactModal = () => {
        closeModal();
    }

    const handleNext = (e) => {

        ReactGA.event({
            category: 'iterates',
            action: 'click ',
            label: `iterates next and previous `
        });

        e.stopPropagation();
        if (currentIndex < data.length - 1) {
            updateCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = (e) => {
        ReactGA.event({
            category: 'iterates',
            action: 'click ',
            label: `iterates next and previous `
        });
        e.stopPropagation();
        if (currentIndex > 0) {
            updateCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="navigation-arrows">
                <img src={ArrowLeft} alt="Previous" onClick={handlePrevious} className={`arrow-left ${currentIndex === 0 ? 'disabled' : ''}`} />
                <img src={ArrowRight} alt="Next" onClick={handleNext} className={`arrow-right ${currentIndex === data.length - 1 ? 'disabled' : ''}`} />
            </div>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <img className="close-button" src={ButtonClose} onClick={closeModal} alt="Close" />
                <TourPackage data={currentData} closeNavContactModal={closeNavContactModal} />
                <div className='enquiry-modal-button'>
                    <button className="enquire-button" onClick={handleEnquire}>Enquire Now!!</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
