import React, { useState, useEffect } from 'react';
import './style.css';
import CalendarDay from 'assets/icons/calendar-white.svg';
import CloudSun from 'assets/icons/cloud-sun.svg';
import ViewAll from 'assets/icons/view-all.svg';
import Modal from 'components/modal/modal';
import ContactUsModal from 'components/contact-us-modal/contact-us-modal';
import ReactGA from 'react-ga4';

function ImageGallery({ images, isMobileView }) {
    const [showAll, setShowAll] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);

    const itemsPerRow = isMobileView ? 2 : 5

    const handleImageClick = (index) => {
        setSelectedIndex(index);
        setShowModal(true);

        ReactGA.event({
            category: 'Packages',
            action: 'click ',
            label: `package select `
        });
    };

    const openContactModal = () => {
        setIsContactModalOpen(true);
    };

    const closeContactModal = () => {
        setIsContactModalOpen(false);
        ReactGA.event({
            category: 'Packages',
            action: 'click ',
            label: `close button clicked `
        });
    };

    const toggleShowAll = () => {
        const element = document.getElementById('packages');
        if (element && showAll) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        setShowAll(!showAll);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedIndex(null);
    };

    const handleEnquiryModal = () => {
        ReactGA.event({
            category: 'itinerary',
            action: 'click ',
            label: `enquiry clicked from itinerary `
        });

        setIsContactModalOpen(true);
    };

    const handleCurrentIndex = (index) => {
        setSelectedIndex(index);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        if (showModal) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showModal]);

    if (!images || images.length === 0) {
        return null;
    }

    return (
        <div id="top-element" className="image-gallery">
            <div className={`image-grid-wrapper ${showAll ? 'show-all' : ''}`}>
                {images.slice(0, showAll ? images.length : itemsPerRow).map((image, index) => (
                    <div key={index} className="image-tile  mx-1" onClick={() => handleImageClick(index)}>
                        <img src={image.url} alt={image.alt} id='package-image' />
                        <div className='image-top-text-and-image d-flex justify-content-between' style={{ paddingBottom: '2rem' }}>
                            <img className="feature-icon mx-2 mt-4" src={CloudSun} alt="Time Icon" />
                            <div className='image-top-text d-flex justify-content-end'>
                                <img className="feature-icon mx-2 mt-4" src={CalendarDay} alt="Location Icon" />
                                <span className="mt-4" style={{ fontWeight: 'bold' }}>{image.totalDays}D</span>
                            </div>
                        </div>
                        <div className="image-bottom-text text-uppercase roboto-medium">
                            <span className="image-text-content merriweather-light">{image.title}</span>
                        </div>
                    </div>
                ))}
            </div>
            {images.length > 5 && (
                <div className="view-all-container">
                    <span className="view-all merriweather-light my-3" onClick={toggleShowAll}>
                        <img src={ViewAll} alt="View All Icon" className={showAll ? 'rotated' : ''} />
                        {showAll ? 'Hide' : 'View All'}
                    </span>
                </div>
            )}
            {selectedIndex !== null && (
                <Modal
                    showModal={showModal}
                    closeModal={closeModal}
                    data={images}
                    currentIndex={selectedIndex}
                    updateCurrentIndex={handleCurrentIndex}
                    notifyParent={handleEnquiryModal}
                />
            )}
            <ContactUsModal isOpenContactModal={isContactModalOpen} onCloseContact={closeContactModal} />
        </div>
    );
}

export default ImageGallery;
// 212 max height of the image 
// footer phone icon color theme color
// footer bar image  width 122px 
// footer height should  reduce 
// follow size and  image size  and place it center for 
// footer address and contact should be same row  and follow us  as new row 
// our partner increase  padding from top and reduce the padding to the images 
// reduce the overall height of the the review section and also reduce the size of the the name 
// package bottom text starting with the 'get in' from a new line
// package filter : remove left margin in
// menu should be open   from right side and background should be back 
// package icon size  should be same and space between
//  contact us modal top and bottom margin should be same as left and right margin
// experience  lap  -6:6  mobile 12 12 
//   epx moda should be central and  border size should be reduced
// add a total padding  adn heading wll be 26px and text content should be  14px 
// our resort casousel padding land r 