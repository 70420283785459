import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import './spinner.css';

const Spinner = ({ loading }) => {
    if (!loading) return null;

    return (
        <div className="spinner-overlay">
            <div className="spinner-container">
                <ClipLoader color="#113c4e" t loading={loading} thickness={4} size={150} />
            </div>
        </div>
    );
};

export default Spinner;
