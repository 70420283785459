import React, { useState, useEffect } from 'react';
import './style.css';

import Checked from 'assets/icons/checked.svg';
import Calender from 'assets/icons/calendar-white.svg';
import Close from 'assets/icons/circle-rounded.svg';



const TourPackage = ({ data, closeNavContactModal }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const itemsPerRow = isMobile ? 2 : 3;


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const rows = [];
    const firstRow = data.days.slice(0, itemsPerRow - 1);
    const remainingDays = data.days.slice(itemsPerRow - 1);

    for (let i = 0; i < remainingDays.length; i += itemsPerRow) {
        rows.push(remainingDays.slice(i, i + itemsPerRow));
    }

    const renderFirstRow = () => (
        <div className="day-row">
            {firstRow.map((day, index) => (
                <div className="day-container" key={index}>
                    <div className="day">
                        <h6>{day.day}</h6>
                        <div className='day-image-container'>
                            <img className='day-image' src={day.image} alt={day.location} />
                        </div>
                        <h6 className='location-header'>{day.location}</h6>
                        <ul className='merriweather-light'>
                            {day.activities.map((activity, idx) => (
                                <li key={idx}>{activity}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
            <div className="inclusions">
                <h5 className='merriweather-light text-start' id='inclusion-text'>INCLUSIONS</h5>
                <ul>
                    {data.inclusions.map((inclusion, index) => (
                        <li className='roboto-regular font-size-15' key={index}>
                            <img style={{ width: '15px' }} src={Checked} alt='checked icon' />
                            {inclusion}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );

    const renderRemainingRows = () => (
        rows.map((row, rowIndex) => (
            <div className="day-row" key={rowIndex}>
                {row.map((day, index) => (
                    <div className="day-container" key={index}>
                        <div className="day">
                            <h6>{day.day}</h6>
                            <div className='day-image-container'>
                                <img className='day-image' src={day.image} alt={day.location} />
                            </div>
                            <h6 className='location-header'>{day.location}</h6>
                            <ul className='merriweather-light'>
                                {day.activities.map((activity, idx) => (
                                    <li key={idx}>{activity}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        ))
    );

    return (
        <div>
            <div className="tour-package-container">
                <div className='popup-lines top-line-1'></div>
                <div className='popup-lines top-line-2'></div>
                <div className='popup-lines left-line-1'></div>
                <div className='popup-lines right-line-1'></div>

                <div className="tour-package">
                    <header className="header">
                        <h1 className='cinzel-saveller header-font'>{data.title}</h1>
                        <div className='d-flex flex-direction-row justify-content-center'>

                            <img src={Calender} className='itinerary-calender-icon' alt='calendar icon' />
                            <span className='roboto-regular duration-text'>{data.duration}</span>
                            <img src={Close} onClick={closeNavContactModal} className="itinerary-close-btn" alt="Close" />

                        </div>
                    </header>
                    <div className="content">
                        {renderFirstRow()}
                        {renderRemainingRows()}
                    </div>
                    <div className="bottom-left"></div>
                    <div className="bottom-right"></div>
                </div>


                <div className='popup-lines bottom-line-1'></div>
                <div className='popup-lines bottom-line-2'></div>
            </div>

        </div>
    );
};

export default TourPackage;
