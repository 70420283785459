import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollableComponent = ({ children }) => {
  const scrollableRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (scrollableRef.current) scrollableRef.current.scrollTo(0, 0);
  }, [pathname]);

  return (<div ref={scrollableRef}>
    {children}
  </div>)
};


export default ScrollableComponent;
