import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Devonshire from 'assets/partners/DEVONSHIRE.jpg';
import EdenWoods from 'assets/partners/EDEN WOODS MUNNAR.jpg';
import GREEN from 'assets/partners/GREEN WOODS THEKKADY.jpg';
import HILLSANDHUES from 'assets/partners/HILLS & HUES THEKKADY.jpg';
import JEEVESS from 'assets/partners/JEEVESS AYURVEDIC RESORT WAYANAD.jpg';
import MONTICLE from 'assets/partners/MONTICLE GLAMPS VAGAMON.jpg';
import MOUNTAIN from 'assets/partners/MOUNTAIN SHADOW WAYANAD.jpg';
import PARAKKATTU from 'assets/partners/PARAKKATTU RESORT MUNNAR.jpg';
import PUNNAMADA from 'assets/partners/PUNNAMADA.jpg';
import STERLINGAlappy from 'assets/partners/STERLING ALLEPPEY.jpg';
import UDAY from 'assets/partners/UDAY.jpg';

import SAI from 'assets/partners/SAI  MIKA CHERRAPUNJI.jpg';

import './partner.css';

function PartnerHotels({ isKerala = false, isNortheast = false }) {
    let images = [
        Devonshire,
        EdenWoods,
        GREEN, HILLSANDHUES,
        JEEVESS,
        MONTICLE,
        MOUNTAIN,
        PARAKKATTU,
        PUNNAMADA,
        STERLINGAlappy,
        UDAY, SAI
    ];


    if (isKerala) {
        images = [
            Devonshire,
            EdenWoods,
            GREEN, HILLSANDHUES,
            JEEVESS,
            MONTICLE,
            MOUNTAIN,
            PARAKKATTU,
            PUNNAMADA,
            STERLINGAlappy,
            UDAY
        ]
    } else if (isNortheast) {
        images = [SAI]
    }


    return (
        <div className="partnerSlider">
            <Carousel fade interval={300000}>
                {images.map((image, index) => (
                    <Carousel.Item key={index}>
                        <img src={image} alt={`Slide ${index + 1}`} className="d-block" />
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
}

export default PartnerHotels;
