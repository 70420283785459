import ReactGA from "react-ga4";

const initializeGA = () => {
    ReactGA.initialize('G-WHTBQ5BD34');

};

const logPageView = () => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
};

export { initializeGA, logPageView };
